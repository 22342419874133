<template>
  <!-- MISC -->

  <h2>Micro-parcels</h2>

  <MicroParcels
    :microParcelsData="microParcelsData"
    :isEditable="isEditable"
    :displayHeaders="displayHeaders"
    :displayBorders="displayBorders"
    :colNumber="colNumber"
    :lineNumber="lineNumber"
  />

  <div class="grid grid--compact margin-top">
    <!-- <label>Colonnes <input type="number" v-model="colNumber"></label>
    <label>Lignes <input type="number" v-model="lineNumber"></label> -->
    <label>isEditable <input type="checkbox" v-model="isEditable" /></label>
    <label
      >DisplayHeaders <input type="checkbox" v-model="displayHeaders"
    /></label>
    <label
      >DisplayBorders <input type="checkbox" v-model="displayBorders"
    /></label>
  </div>

  <div class="margin-top">
    <Btn text="Full modal" color="black" @click="isModalOpen = true" />
  </div>

  <!-- Full modal -->
  <Modal
    title="Full modal title"
    :active="isModalOpen"
    :data="{}"
    @modal-close="isModalOpen = false"
    size="full"
    no-padding
  >
    <template v-slot:modal-body="{}">
      <MicroParcels
        :microParcelsData="microParcelsData"
        :isEditable="isEditable"
        :displayHeaders="displayHeaders"
        :displayBorders="displayBorders"
        :colNumber="colNumber"
        :lineNumber="lineNumber"
        canPanY
      />
    </template>
    <template v-slot:modal-footer="{}">
      <Btn text="Annuler" @click="isModalOpen = false" />
      <Btn
        text="Supprimer"
        @click="isModalOpen = false"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import MicroParcels from '@/components/micro-parcels/MicroParcels.vue'
import Btn from '@/components/base/Btn.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'StyleguideMiscView',

  components: {
    MicroParcels,
    Btn,
    Modal,
  },

  data() {
    return {
      isEditable: true,
      displayHeaders: true,
      displayBorders: true,
      microParcelsData: [],
      colNumber: 15,
      lineNumber: 12,
      isModalOpen: false,
    }
  },

  mounted() {
    this.microParcelsData = [
      {
        id: 591,
        x: 1,
        y: 1,
        repetition: 5,
        modalite_ordre: null,
        coordonnees: '101',
        numero: '507',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 33,
          numero: null,
          designation: ' x  / APN 04 2',
          destruction: null,
          ordre: 7,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 592,
        x: 1,
        y: 2,
        repetition: 5,
        modalite_ordre: null,
        coordonnees: '102',
        numero: '502',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 28,
          numero: null,
          designation: ' x  / REVYSTAR  XL 0,9',
          destruction: null,
          ordre: 2,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 593,
        x: 1,
        y: 3,
        repetition: 5,
        modalite_ordre: null,
        coordonnees: '103',
        numero: '506',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 32,
          numero: null,
          designation: ' x  / PAVECTO 60 1,2 + ALANA STAR 0,5',
          destruction: null,
          ordre: 6,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 594,
        x: 1,
        y: 4,
        repetition: 5,
        modalite_ordre: null,
        coordonnees: '104',
        numero: '503',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 29,
          numero: null,
          designation: ' x  / REVYSTAR  XL 0,75',
          destruction: null,
          ordre: 3,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 595,
        x: 1,
        y: 5,
        repetition: 5,
        modalite_ordre: null,
        coordonnees: '105',
        numero: '509',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 35,
          numero: null,
          designation: ' x  / ELATUS PLUS 0,6 + METCOSTAR 90 0,6',
          destruction: null,
          ordre: 9,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 596,
        x: 1,
        y: 6,
        repetition: 5,
        modalite_ordre: null,
        coordonnees: '106',
        numero: '505',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 31,
          numero: null,
          designation: ' x  / PAVECTO 60 1,2 + ELATUS PLUS 0,6',
          destruction: null,
          ordre: 5,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 597,
        x: 1,
        y: 7,
        repetition: 5,
        modalite_ordre: null,
        coordonnees: '107',
        numero: '508',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 34,
          numero: null,
          designation: ' x  / F170BCS 0,5 + METCOSTAR 90 0,5',
          destruction: null,
          ordre: 8,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 598,
        x: 1,
        y: 8,
        repetition: 5,
        modalite_ordre: null,
        coordonnees: '108',
        numero: '504',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 30,
          numero: null,
          designation: ' x  / AMPLITUDE 0,55 + PRIAXOR EC 0,55',
          destruction: null,
          ordre: 4,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 599,
        x: 1,
        y: 9,
        repetition: 5,
        modalite_ordre: null,
        coordonnees: '109',
        numero: '510',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 36,
          numero: null,
          designation: ' x  / ELATUS PLUS 0,6 + M0050 0,4',
          destruction: null,
          ordre: 10,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 600,
        x: 1,
        y: 10,
        repetition: 5,
        modalite_ordre: null,
        coordonnees: '110',
        numero: '501',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 27,
          numero: null,
          designation: ' x  /  x ',
          destruction: null,
          ordre: 1,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 601,
        x: 2,
        y: 1,
        repetition: 4,
        modalite_ordre: null,
        coordonnees: '201',
        numero: '410',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 36,
          numero: null,
          designation: ' x  / ELATUS PLUS 0,6 + M0050 0,4',
          destruction: null,
          ordre: 10,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 602,
        x: 2,
        y: 2,
        repetition: 4,
        modalite_ordre: null,
        coordonnees: '202',
        numero: '409',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 35,
          numero: null,
          designation: ' x  / ELATUS PLUS 0,6 + METCOSTAR 90 0,6',
          destruction: null,
          ordre: 9,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 603,
        x: 2,
        y: 3,
        repetition: 4,
        modalite_ordre: null,
        coordonnees: '203',
        numero: '405',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 31,
          numero: null,
          designation: ' x  / PAVECTO 60 1,2 + ELATUS PLUS 0,6',
          destruction: null,
          ordre: 5,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 604,
        x: 2,
        y: 4,
        repetition: 4,
        modalite_ordre: null,
        coordonnees: '204',
        numero: '401',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 27,
          numero: null,
          designation: ' x  /  x ',
          destruction: null,
          ordre: 1,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 605,
        x: 2,
        y: 5,
        repetition: 4,
        modalite_ordre: null,
        coordonnees: '205',
        numero: '408',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 34,
          numero: null,
          designation: ' x  / F170BCS 0,5 + METCOSTAR 90 0,5',
          destruction: null,
          ordre: 8,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 606,
        x: 2,
        y: 6,
        repetition: 4,
        modalite_ordre: null,
        coordonnees: '206',
        numero: '406',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 32,
          numero: null,
          designation: ' x  / PAVECTO 60 1,2 + ALANA STAR 0,5',
          destruction: null,
          ordre: 6,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 607,
        x: 2,
        y: 7,
        repetition: 4,
        modalite_ordre: null,
        coordonnees: '207',
        numero: '407',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 33,
          numero: null,
          designation: ' x  / APN 04 2',
          destruction: null,
          ordre: 7,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 608,
        x: 2,
        y: 8,
        repetition: 4,
        modalite_ordre: null,
        coordonnees: '208',
        numero: '402',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 28,
          numero: null,
          designation: ' x  / REVYSTAR  XL 0,9',
          destruction: null,
          ordre: 2,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 609,
        x: 2,
        y: 9,
        repetition: 4,
        modalite_ordre: null,
        coordonnees: '209',
        numero: '403',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 29,
          numero: null,
          designation: ' x  / REVYSTAR  XL 0,75',
          destruction: null,
          ordre: 3,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 610,
        x: 2,
        y: 10,
        repetition: 4,
        modalite_ordre: null,
        coordonnees: '210',
        numero: '404',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 30,
          numero: null,
          designation: ' x  / AMPLITUDE 0,55 + PRIAXOR EC 0,55',
          destruction: null,
          ordre: 4,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 611,
        x: 3,
        y: 1,
        repetition: 3,
        modalite_ordre: null,
        coordonnees: '301',
        numero: '310',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 36,
          numero: null,
          designation: ' x  / ELATUS PLUS 0,6 + M0050 0,4',
          destruction: null,
          ordre: 10,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 612,
        x: 3,
        y: 2,
        repetition: 3,
        modalite_ordre: null,
        coordonnees: '302',
        numero: '301',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 27,
          numero: null,
          designation: ' x  /  x ',
          destruction: null,
          ordre: 1,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 613,
        x: 3,
        y: 3,
        repetition: 3,
        modalite_ordre: null,
        coordonnees: '303',
        numero: '306',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 32,
          numero: null,
          designation: ' x  / PAVECTO 60 1,2 + ALANA STAR 0,5',
          destruction: null,
          ordre: 6,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 614,
        x: 3,
        y: 4,
        repetition: 3,
        modalite_ordre: null,
        coordonnees: '304',
        numero: '305',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 31,
          numero: null,
          designation: ' x  / PAVECTO 60 1,2 + ELATUS PLUS 0,6',
          destruction: null,
          ordre: 5,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 615,
        x: 3,
        y: 5,
        repetition: 3,
        modalite_ordre: null,
        coordonnees: '305',
        numero: '308',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 34,
          numero: null,
          designation: ' x  / F170BCS 0,5 + METCOSTAR 90 0,5',
          destruction: null,
          ordre: 8,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 616,
        x: 3,
        y: 6,
        repetition: 3,
        modalite_ordre: null,
        coordonnees: '306',
        numero: '304',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 30,
          numero: null,
          designation: ' x  / AMPLITUDE 0,55 + PRIAXOR EC 0,55',
          destruction: null,
          ordre: 4,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 617,
        x: 3,
        y: 7,
        repetition: 3,
        modalite_ordre: null,
        coordonnees: '307',
        numero: '303',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 29,
          numero: null,
          designation: ' x  / REVYSTAR  XL 0,75',
          destruction: null,
          ordre: 3,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 618,
        x: 3,
        y: 8,
        repetition: 3,
        modalite_ordre: null,
        coordonnees: '308',
        numero: '302',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 28,
          numero: null,
          designation: ' x  / REVYSTAR  XL 0,9',
          destruction: null,
          ordre: 2,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 619,
        x: 3,
        y: 9,
        repetition: 3,
        modalite_ordre: null,
        coordonnees: '309',
        numero: '307',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 33,
          numero: null,
          designation: ' x  / APN 04 2',
          destruction: null,
          ordre: 7,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 620,
        x: 3,
        y: 10,
        repetition: 3,
        modalite_ordre: null,
        coordonnees: '310',
        numero: '309',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 35,
          numero: null,
          designation: ' x  / ELATUS PLUS 0,6 + METCOSTAR 90 0,6',
          destruction: null,
          ordre: 9,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 621,
        x: 4,
        y: 1,
        repetition: 2,
        modalite_ordre: null,
        coordonnees: '401',
        numero: '209',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 35,
          numero: null,
          designation: ' x  / ELATUS PLUS 0,6 + METCOSTAR 90 0,6',
          destruction: null,
          ordre: 9,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 622,
        x: 4,
        y: 2,
        repetition: 2,
        modalite_ordre: null,
        coordonnees: '402',
        numero: '210',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 36,
          numero: null,
          designation: ' x  / ELATUS PLUS 0,6 + M0050 0,4',
          destruction: null,
          ordre: 10,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 623,
        x: 4,
        y: 3,
        repetition: 2,
        modalite_ordre: null,
        coordonnees: '403',
        numero: '207',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 33,
          numero: null,
          designation: ' x  / APN 04 2',
          destruction: null,
          ordre: 7,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 624,
        x: 4,
        y: 4,
        repetition: 2,
        modalite_ordre: null,
        coordonnees: '404',
        numero: '205',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 31,
          numero: null,
          designation: ' x  / PAVECTO 60 1,2 + ELATUS PLUS 0,6',
          destruction: null,
          ordre: 5,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 625,
        x: 4,
        y: 5,
        repetition: 2,
        modalite_ordre: null,
        coordonnees: '405',
        numero: '203',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 29,
          numero: null,
          designation: ' x  / REVYSTAR  XL 0,75',
          destruction: null,
          ordre: 3,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 626,
        x: 4,
        y: 6,
        repetition: 2,
        modalite_ordre: null,
        coordonnees: '406',
        numero: '204',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 30,
          numero: null,
          designation: ' x  / AMPLITUDE 0,55 + PRIAXOR EC 0,55',
          destruction: null,
          ordre: 4,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 627,
        x: 4,
        y: 7,
        repetition: 2,
        modalite_ordre: null,
        coordonnees: '407',
        numero: '201',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 27,
          numero: null,
          designation: ' x  /  x ',
          destruction: null,
          ordre: 1,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 628,
        x: 4,
        y: 8,
        repetition: 2,
        modalite_ordre: null,
        coordonnees: '408',
        numero: '202',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 28,
          numero: null,
          designation: ' x  / REVYSTAR  XL 0,9',
          destruction: null,
          ordre: 2,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 629,
        x: 4,
        y: 9,
        repetition: 2,
        modalite_ordre: null,
        coordonnees: '409',
        numero: '208',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 34,
          numero: null,
          designation: ' x  / F170BCS 0,5 + METCOSTAR 90 0,5',
          destruction: null,
          ordre: 8,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 630,
        x: 4,
        y: 10,
        repetition: 2,
        modalite_ordre: null,
        coordonnees: '410',
        numero: '206',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 32,
          numero: null,
          designation: ' x  / PAVECTO 60 1,2 + ALANA STAR 0,5',
          destruction: null,
          ordre: 6,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 631,
        x: 5,
        y: 1,
        repetition: 1,
        modalite_ordre: null,
        coordonnees: '501',
        numero: '101',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 27,
          numero: null,
          designation: ' x  /  x ',
          destruction: null,
          ordre: 1,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 632,
        x: 5,
        y: 2,
        repetition: 1,
        modalite_ordre: null,
        coordonnees: '502',
        numero: '108',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 34,
          numero: null,
          designation: ' x  / F170BCS 0,5 + METCOSTAR 90 0,5',
          destruction: null,
          ordre: 8,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 633,
        x: 5,
        y: 3,
        repetition: 1,
        modalite_ordre: null,
        coordonnees: '503',
        numero: '107',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 33,
          numero: null,
          designation: ' x  / APN 04 2',
          destruction: null,
          ordre: 7,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 634,
        x: 5,
        y: 4,
        repetition: 1,
        modalite_ordre: null,
        coordonnees: '504',
        numero: '110',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 36,
          numero: null,
          designation: ' x  / ELATUS PLUS 0,6 + M0050 0,4',
          destruction: null,
          ordre: 10,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 635,
        x: 5,
        y: 5,
        repetition: 1,
        modalite_ordre: null,
        coordonnees: '505',
        numero: '102',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 28,
          numero: null,
          designation: ' x  / REVYSTAR  XL 0,9',
          destruction: null,
          ordre: 2,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 636,
        x: 5,
        y: 6,
        repetition: 1,
        modalite_ordre: null,
        coordonnees: '506',
        numero: '104',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 30,
          numero: null,
          designation: ' x  / AMPLITUDE 0,55 + PRIAXOR EC 0,55',
          destruction: null,
          ordre: 4,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 637,
        x: 5,
        y: 7,
        repetition: 1,
        modalite_ordre: null,
        coordonnees: '507',
        numero: '109',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 35,
          numero: null,
          designation: ' x  / ELATUS PLUS 0,6 + METCOSTAR 90 0,6',
          destruction: null,
          ordre: 9,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 638,
        x: 5,
        y: 8,
        repetition: 1,
        modalite_ordre: null,
        coordonnees: '508',
        numero: '103',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 29,
          numero: null,
          designation: ' x  / REVYSTAR  XL 0,75',
          destruction: null,
          ordre: 3,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 639,
        x: 5,
        y: 9,
        repetition: 1,
        modalite_ordre: null,
        coordonnees: '509',
        numero: '105',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 31,
          numero: null,
          designation: ' x  / PAVECTO 60 1,2 + ELATUS PLUS 0,6',
          destruction: null,
          ordre: 5,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
      {
        id: 640,
        x: 5,
        y: 10,
        repetition: 1,
        modalite_ordre: null,
        coordonnees: '510',
        numero: '106',
        type: {
          id: 1,
          uid: 'MODALITE',
          designation: 'Modalité',
        },
        modalite: {
          id: 32,
          numero: null,
          designation: ' x  / PAVECTO 60 1,2 + ALANA STAR 0,5',
          destruction: null,
          ordre: 6,
          type: {
            id: 1,
            uid: 'PROTOCOLE',
            designation: 'Modalité du protocole',
          },
        },
      },
    ]
  },
}
</script>

<style lang="scss" scoped>
</style>
